.ui-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}
.ui-visible {
    visibility: visible
}
.ui-invisible {
    visibility: hidden
}
.ui-fixed {
    position: fixed
}
.ui-relative {
    position: relative
}
.ui-left-0 {
    left: 0px
}
.ui-right-0 {
    right: 0px
}
.ui-top-0 {
    top: 0px
}
.ui-z-50 {
    z-index: 50
}
.ui-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
}
.ui-mb-2 {
    margin-bottom: 0.5rem
}
.ui-mb-3 {
    margin-bottom: 0.75rem
}
.ui-mb-6 {
    margin-bottom: 1.5rem
}
.ui-ml-auto {
    margin-left: auto
}
.ui-mr-2 {
    margin-right: 0.5rem
}
.ui-line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2
}
.ui-block {
    display: block
}
.ui-inline-block {
    display: inline-block
}
.ui-flex {
    display: flex
}
.ui-inline-flex {
    display: inline-flex
}
.ui-hidden {
    display: none
}
.ui-h-14 {
    height: 3.5rem
}
.ui-h-4 {
    height: 1rem
}
.ui-h-6 {
    height: 1.5rem
}
.ui-h-full {
    height: 100%
}
.ui-h-screen {
    height: 100vh
}
.ui-max-h-full {
    max-height: 100%
}
.ui-w-11\/12 {
    width: 91.666667%
}
.ui-w-4 {
    width: 1rem
}
.ui-w-6 {
    width: 1.5rem
}
.ui-w-full {
    width: 100%
}
.ui-max-w-full {
    max-width: 100%
}
.ui-max-w-screen-lg {
    max-width: 1024px
}
.ui-max-w-screen-md {
    max-width: 768px
}
.ui-max-w-screen-sm {
    max-width: 640px
}
.ui-max-w-sm {
    max-width: 24rem
}
.ui-flex-1 {
    flex: 1 1 0%
}
.ui-cursor-not-allowed {
    cursor: not-allowed
}
.ui-flex-row {
    flex-direction: row
}
.ui-flex-row-reverse {
    flex-direction: row-reverse
}
.ui-flex-col {
    flex-direction: column
}
.ui-flex-wrap {
    flex-wrap: wrap
}
.ui-items-center {
    align-items: center
}
.ui-justify-center {
    justify-content: center
}
.ui-justify-between {
    justify-content: space-between
}
.ui-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
}
.ui-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}
.ui-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
}
.ui-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
}
.ui-self-center {
    align-self: center
}
.ui-overflow-hidden {
    overflow: hidden
}
.ui-overflow-y-auto {
    overflow-y: auto
}
.ui-overflow-x-hidden {
    overflow-x: hidden
}
.ui-hyphens-auto {
    -webkit-hyphens: auto;
            hyphens: auto
}
.ui-rounded {
    border-radius: 0.25rem
}
.ui-rounded-lg {
    border-radius: 0.5rem
}
.ui-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
}
.ui-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
}
.ui-border {
    border-width: 1px
}
.ui-border-b {
    border-bottom-width: 1px
}
.ui-border-l {
    border-left-width: 1px
}
.ui-border-r {
    border-right-width: 1px
}
.ui-border-t {
    border-top-width: 1px
}
.ui-border-black {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity))
}
.ui-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity))
}
.ui-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity))
}
.ui-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity))
}
.ui-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.ui-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}
.ui-bg-gray-900\/\[\.3\] {
    background-color: rgb(17 24 39 / .3)
}
.ui-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.ui-p-2 {
    padding: 0.5rem
}
.ui-p-2\.5 {
    padding: 0.625rem
}
.ui-p-3 {
    padding: 0.75rem
}
.ui-p-4 {
    padding: 1rem
}
.ui-p-6 {
    padding: 1.5rem
}
.ui-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}
.ui-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
}
.ui-px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}
.ui-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}
.ui-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
}
.ui-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}
.ui-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
}
.ui-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}
.ui-pb-3 {
    padding-bottom: 0.75rem
}
.ui-pr-3 {
    padding-right: 0.75rem
}
.ui-text-center {
    text-align: center
}
.ui-align-middle {
    vertical-align: middle
}
.ui-text-base {
    font-size: 1rem;
    line-height: 1.5rem
}
.ui-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}
.ui-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
}
.ui-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}
.ui-text-xs {
    font-size: 0.75rem;
    line-height: 1rem
}
.ui-font-bold {
    font-weight: 700
}
.ui-font-medium {
    font-weight: 500
}
.ui-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}
.ui-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))
}
.ui-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}
.ui-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity))
}
.ui-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity))
}
.ui-text-red-700 {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity))
}
.ui-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.ui-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.ui-hyphens-auto {
    -webkit-hyphens: auto;
            hyphens: auto
}
.hover\:ui-bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity))
}
.hover\:ui-bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}
.hover\:ui-text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}
.focus\:ui-border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity))
}
.focus\:ui-text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}
@media (min-width: 640px) {
    .sm\:ui-text-xs {
        font-size: 0.75rem;
        line-height: 1rem
    }
}
@media (min-width: 768px) {
    .md\:ui-visible {
        visibility: visible
    }
    .md\:ui-relative {
        position: relative
    }
    .md\:ui-inset-0 {
        inset: 0px
    }
    .md\:ui-z-auto {
        z-index: auto
    }
    .md\:ui-hidden {
        display: none
    }
    .md\:ui-h-auto {
        height: auto
    }
    .md\:ui-w-auto {
        width: auto
    }
    .md\:ui-max-w-full {
        max-width: 100%
    }
    .md\:ui-space-x-3 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.75rem * var(--tw-space-x-reverse));
        margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
    }
    .md\:ui-border-0 {
        border-width: 0px
    }
    .md\:ui-bg-transparent {
        background-color: transparent
    }
    .md\:ui-p-0 {
        padding: 0px
    }
    .md\:ui-px-0 {
        padding-left: 0px;
        padding-right: 0px
    }
    .md\:ui-px-3 {
        padding-left: 0.75rem;
        padding-right: 0.75rem
    }
}
@media (min-width: 1024px) {
    .lg\:ui-px-0 {
        padding-left: 0px;
        padding-right: 0px
    }
    .lg\:ui-pr-3 {
        padding-right: 0.75rem
    }
}
