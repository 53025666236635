/* Import styles from swiper package to style slider, e.g. product slider */
@import "swiper/swiper.min.css";
@import "swiper/modules/free-mode.min.css";
@import "swiper/modules/scrollbar.min.css";

/* Import base (or global) styles are the styles at the beginning of a stylesheet
that set useful defaults for basic HTML elements like */
@import "tailwindcss/base";

/* Import styles from component library */
@import "@precomposer/ui/dist/index.css";
@import "tailwindcss/components";

/* Import the tailwind utility classes */
@import "tailwindcss/utilities";

.swiper-slide-thumb-active {
  opacity: 1;
}

.notification-bar-color {
  background: #E7F227;
}
